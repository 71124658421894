import Rails from "rails-ujs";
import $ from "jquery";
import Turbolinks from "turbolinks";
import "foundation-sites";

import "application.scss";

import "images/bg.jpg";
import "images/lgp-logo.svg";
import "images/tick.svg";
import "images/favicon.png";
import "images/facebook-image.jpg";

Rails.start();
Turbolinks.start();

$(document).on("turbolinks:load", function() {
  $(document).foundation();

  $(".js-callout-close").on("click", function() {
    $(this)
      .closest(".callout")
      .remove();
  });
});
